import React, { Component, Fragment, useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import https from 'https';
import Ticket from '../Ticket';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
//import ReCAPTCHA from "react-google-recaptcha";
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import { Link } from 'react-router-dom';
import ReactToPdf from 'react-to-pdf';
import moment from 'moment';

const Formulario = props => {
    const CATALOGO_PROMOCIONES = ['Escrito Inicial de Demanda', 'Contestación de Demanda', 'Promoción', 'Alegatos', 'Informe de Autoridad', 'Recurso', 'Incidente', 'Amparo', 'Otros'];
    const CATALOGO_SALAS = [
        { id: 3, ShortName: 'I' },
        { id: 4, ShortName: 'II' },
        { id: 5, ShortName: 'III' }];

    const [catSalas, setCatSalas] = useState(CATALOGO_SALAS);
    const CATALOGO_FORMATOS = [{ id: 2, name: "[FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [SALA]" },
    { id: 3, name: "'APELACIÓN' [FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [PLENO]" },
    { id: 4, name: "'EXHORTO' [FOLIO] / [EJERCICIO]" },
    { id: 5, name: "'RECLAMACIÓN' [FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [SALA]" },
    { id: 6, name: "'REVISIÓN' [FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [PLENO]" },
    { id: 7, name: "'INCIDENTE DE RECUSACIÓN' [FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [PLENO]" },
    { id: 8, name: "'EXPEDIENTE VARIOS' [FOLIO] / [EJERCICIO]" },
    { id: 9, name: "'ACUMULACIÓN' [FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [PLENO]" },
    { id: 10, name: "'RECURSO DE INCONFORMIDAD' [FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [SALA]" }];
    const CATALOGO_TIPOS = [{ id: 1, ShortName: 'VARIOS' }, { id: 2, ShortName: 'LPCA' }, { id: 3, ShortName: 'LRA' }];
    const [isLoading, setIsLoading] = useState(false);

    const [tipo, setTipo] = useState(0);
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [expediente, setExpediente] = useState('');
    const [isExpediente, setIsExpediente] = useState(false);
    const [qr, setQR] = useState('');
    const [qrs, setQRs] = useState([]);
    const [promocionesTipo, setPromocioensTipo] = useState([]);
    const [promocionesTipoFull, setPromocioensTipoFull] = useState([]);
    const QRCode = require('qrcode.react');
    const [isAccept, setIsAccept] = useState(false);
    const [expire, setExpire] = useState("");
    const [sala, setSala] = useState({ id: 3, ShortName: 'I' });
    const [tipodem, setTipodem] = useState({ id: 2, ShortName: 'LPCA' });
    const [year, setYear] = useState("2021");
    const [years, setYears] = useState([2018]);
    const [demandaresp, setDemandaresp] = useState("");
    const [captchauser, setCaptchauser] = useState("");
    const [promovente, setPromovente] = useState("");
    const [cantidadPaquetes, setCantidadPaquetes] = useState(1);
    const [totalPaquetes, setTotalPaquetes] = useState(1);
    const [ticketsref, setTicketsref] = useState([]);
    const refparent = useRef(null);
    const [formato, setFormato] = useState("[FOLIO] / [EJERCICIO] - [PROCEDIMIENTO] - [SALA]");
    const [formatIndex, setFormatIndex] = useState(0);
    const [formatoPrefix, setFormatoPrefix] = useState("APELACIÓN");
    const [formatoID, setFormatoId] = useState(2);
    const [detalles, setDetalles] = useState("");

    const [vlist, setVlist] = useState([]);
    const [vlistselected, setVlistselected] = useState(-1);

    const [tdems, setTdems] = useState([]);
    const [tdemsFull, setTdemsFull] = useState([]);
    const [tdemselected, setTdemselected] = useState(-1);
    const [tdemselectedobj, setTdemselectedObj] = useState(null);

    loadReCaptcha("6LeiBqoaAAAAAF6nsfxzhmv2mg4ONI5HLgEEn4VS");
    const verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        console.log(recaptchaToken, "<= your recaptcha token")
    }

    const updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute();
    }
    const handleSubmit = e => {
        e.preventDefault();

        /* if (validateCaptcha(captchauser) == false) {
             Swal.fire("Incorrecto", "Captcha incorrecto", 'error');
             setCaptchauser("");
             //   loadCaptchaEnginge(6);
             return;
         }*/
        if (email != repeatEmail) {
            Swal.fire("Incorrecto", "El correo y la confirmación no coinciden", 'error');
            return;
        }
        setCaptchauser("");
        //   loadCaptchaEnginge(6);
        let exp = "";
        if (isExpediente) {
            exp += expediente + '/' + year + '-' + tipodem.ShortName + '-' + sala.ShortName;
        }
        setIsLoading(true);

        let saladefault=sala.id;
        if(isExpediente && tdemselectedobj){
            if(tdemselectedobj.demandaReqSala){

            }else{
                saladefault=tdemselectedobj.salaIdDefault;
            }
        }

        let objToPOst = {
            email,
            entregadopor: promovente,
            demsalaid: isExpediente ? saladefault : 1,
            demprocedimientoid: isExpediente ? parseInt(vlistselected) : 1,
            demyearfile: year,
            demnumberfile: isExpediente ? parseInt(expediente) : 0,
            demtipodemandaid: isExpediente ? parseInt(tdemselected) : 1,
            tootalpaquetes: parseInt(cantidadPaquetes),
            demanda: isExpediente ? exp : '***/***',
            promocionTipoId: parseInt(tipo),
            detalle: detalles.trim() === "" ? "NA" : detalles
        }
   //     console.log(objToPOst);
   //     setIsLoading(false);
   //     return;

        const instance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false
            })
        });
        instance.post(`https://apibuzon.tjabcs.gob.mx/api/entrada`, objToPOst).then(res => {
            //console.log(res.data);
            //  setExpire(res.data.expireDate || '');
            setDemandaresp(res.data.shortNameExpediente || '');
            setQRs(res.data.etiquetas);
            // setQR(res.data.tkn);
            setIsLoading(false);
        }).catch(err => {
            //console.log(err);
            Swal.fire("Incorrecto", "Los datos proporcionados (Número y Tipo de Expediente) no coinciden con los registros del TJABCS, por favor verifica.", 'error');
            setIsLoading(false);
        });


        /*axios.post(`http://localhost:8000/api/entrada`, objToPOst)
            .then(res => {
                console.log(res);
                console.log(res.data);
                setQR(res.data);
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
            })*/
    }

    const getVlistAsync = async () => {
        const instance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false
            })
        });
        await instance.get(`https://apibuzon.tjabcs.gob.mx/api/vlist`).then(res => {
            console.log(res);
            if (res.data && res.data.length > 0) {
                setVlistselected(-1);
            }
            setVlist([{ id: -1, shortName: "" }, ...res.data]);
        }).catch(err => {
            console.log(err);
        });

        await instance.get(`https://apibuzon.tjabcs.gob.mx/api/promotipos/0`).then(res => {
            setPromocioensTipoFull(res.data);
        }).catch(err => {
            console.log(err);
        });
        await instance.get(`https://apibuzon.tjabcs.gob.mx/api/demtipos/0`).then(res => {
            setTdemsFull([{ demandaTipoId: -1, demandaTipoShortName: '' }, ...res.data]);
        }).catch(err => {
            console.log(err);
        });


    }
    useEffect(() => {
        if (vlistselected && vlistselected >= 0) {

            const newPromoTipos = promocionesTipoFull.filter(element=>element.procedimientoId==vlistselected);
            if(newPromoTipos && newPromoTipos.length>0){
                setTipo(newPromoTipos[0].promocionTipoId);
                setPromocioensTipo(newPromoTipos);
            }

            const newTdem = tdemsFull.filter(element=>element.procedimientoId==vlistselected);
            if(newTdem && newTdem.length>0){
                setTdemselected(-1);
                setTdems(newTdem);
            }
           
           /* async function fetchMyAPI() {
                const instance = axios.create({
                    httpsAgent: new https.Agent({
                        rejectUnauthorized: false
                    })
                });
                await instance.get(`https://apibuzon.tjabcs.gob.mx/api/promotipos/${vlistselected}`).then(res => {
                    console.log(res);
                    if (res.data && res.data.length > 0) {
                        setTipo(res.data[0].promocionTipoId);
                    }
                    setPromocioensTipo(res.data);
                }).catch(err => {
                    console.log(err);
                });
                await instance.get(`https://apibuzon.tjabcs.gob.mx/api/demtipos/${vlistselected}`).then(res => {
                    console.log(res);
                    if (res.data && res.data.length > 0) {
                        // setTipo(res.data[0].promocionTipoId);
                        setTdemselected(-1);
                    }
                    setTdems([{ demandaTipoId: -1, demandaTipoShortName: '' }, ...res.data]);
                }).catch(err => {
                    console.log(err);
                });

            }
            fetchMyAPI(); */
        }
    }, [vlistselected]);
    useEffect(() => {
        // At instance level
        setIsAccept(false);
        /* const instance = axios.create({
             httpsAgent: new https.Agent({
                 rejectUnauthorized: false
             })
         });
         instance.get(`https://apibuzon.tjabcs.gob.mx/api/entrada`).then(res => {
             console.log(res);
             if (res.data && res.data.length > 0) {
                 setTipo(res.data[0].id);
             }
             setPromocioensTipo(res.data);
         }).catch(err => {
             console.log(err);
         });*/
        getVlistAsync();
        /*
                // At request level
                const agent = new https.Agent({
                    rejectUnauthorized: false
                });
                axios.get('https://something.com/foo', { httpsAgent: agent });
        
                */

        /* axios.get(`https://tjabcs.fortidyndns.com:9403/api/v1/promociontipos/vlist`).then(res => {
             console.log(res);
             setPromocioensTipo(res.data);
         }).catch(err => {
             console.log(err);
         });*/
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
        setYears(range(currentYear, 2018, -1));
        setYear(currentYear);
        //setTipodemByValue('LPCA');
        setSalaByValue("I");
        setCantidadPaquetes(1);
        setPromovente("");
        //console.log(range(currentYear, 2018, -1));

    }, []);


    useEffect(() => {
        let index = CATALOGO_FORMATOS.findIndex(element => element.name === formato);
        setFormatIndex(index);
        let objFormato = CATALOGO_FORMATOS[index];
        if (objFormato && objFormato.id) {
            setFormatoId(objFormato.id);
        }
        // eslint-disable-next-line default-case
        switch (index) {
            case 0:
                setSalaByValue("I");
                setCatSalas(CATALOGO_SALAS);
                break;
            case 1:
                setFormatoPrefix("APELACIÓN");
                setSalaByValue("PLENO");
                setCatSalas([{ id: 2, ShortName: 'PLENO' }]);
                break;
            case 2:
                setFormatoPrefix("EXHORTO");
                break;
            case 3:
                setFormatoPrefix("RECLAMACIÓN");
                setSalaByValue("I");
                setCatSalas(CATALOGO_SALAS);
                break;
            case 4:
                setFormatoPrefix("REVISIÓN");
                setSalaByValue("PLENO");
                setCatSalas([{ id: 2, ShortName: 'PLENO' }]);
                break;
            case 5:
                setFormatoPrefix("INCIDENTE DE RECUSACIÓN");
                setSalaByValue("PLENO");
                setCatSalas([{ id: 2, ShortName: 'PLENO' }]);
                break;
            case 6:
                setFormatoPrefix("EXPEDIENTE VARIOS");
                setSalaByValue("PLENO");
                setCatSalas([{ id: 2, ShortName: 'PLENO' }]);
                break;
            case 7:
                setFormatoPrefix("ACUMULACIÓN");
                setSalaByValue("PLENO");
                setCatSalas([{ id: 2, ShortName: 'PLENO' }]);
                break;
            case 8:
                setFormatoPrefix("RECURSO DE INCONFORMIDAD");
                setSalaByValue("I");
                setCatSalas(CATALOGO_SALAS);
                break;

        }
    }, [formato]);

    useEffect(() => {
        // console.log("tipo",tipo);
        /* if (tipo == 1) {
             // console.log("set false");
             setIsExpediente(false);
         } else {
             // console.log("set true");
             setIsExpediente(true);
         }*/
        console.log("buscando tipo:", tipo);
        //  console.log("en promociones", promocionesTipo);

        if (tipo && tipo > 0) {

            async function fetchMyAPI() {
                const instance = axios.create({
                    httpsAgent: new https.Agent({
                        rejectUnauthorized: false
                    })
                });

                await instance.get(`https://apibuzon.tjabcs.gob.mx/api/demtipos/${vlistselected}`).then(res => {
                    console.log(res);
                    if (res.data && res.data.length > 0) {
                        // setTipo(res.data[0].promocionTipoId);
                        setTdemselected(-1);
                    }
                    setTdems([{ demandaTipoId: -1, demandaTipoShortName: '' }, ...res.data]);
                }).catch(err => {
                    console.log(err);
                });

            }

            fetchMyAPI()
        }
        const objTipo = promocionesTipo.find(element => element.promocionTipoId == tipo);
        if (objTipo) {
            setIsExpediente(objTipo.promoReqDemanda);
        }
    }, [tipo]);

    useEffect(() => {
        if (isAccept) {
            //  loadCaptchaEnginge(6);
            setCaptchauser("");
        }
    }, [isAccept]);

    const setSalaByValue = value => {
        let obj = CATALOGO_SALAS.find(element => element.ShortName === value);
        if (obj) {
            setSala(obj);
        }
    }
    const setTipodemByValue = value => {
        //  let obj = vlist.find(element => element.ShortName === value);
        //   if (obj) {
        //      setTipodem(obj);
        //  }
    }
    const addref = ref => {
        ticketsref.push(ref);
    }

    const downloadtickets = async () => {

        setIsLoading(true);
        await ticketsref.forEach(async (element) => {
            await exportComponentAsPNG(element)
            //await exportComponentAsPDF(element)
        })

        setIsLoading(false);


    }

    useEffect(() => {
        if (tdemselected >= 0) {
            const objtdem = tdems.find(element => element.demandaTipoId == tdemselected);
            if (objtdem) {
                console.log(objtdem);
                setTdemselectedObj(objtdem);
            }

        }

    }, [tdemselected]);
    useEffect(()=>{
        console.log("tdemselectedobj",tdemselectedobj);
    },[tdemselectedobj]);
    return (
        <Fragment >

            {//this.state.loading ? <div></div> :
                <div style={{ backgroundColor: "#c0b49c" }}>
                    <div className="container loginbackground" style={{ padding: '3%' }} >
                        <div className="row d-flex ">
                            <div className="col-12 col-md-12 col-lg-12 cardlogin" >

                                <LoadingOverlay
                                    active={isLoading}
                                    spinner
                                    text='Espere un momento...'>
                                    <div className=" bg-white" style={{ padding: '10px' }}>
                                        <Link to="/" className="btn btn-sm btn-primary">Volver</Link>

                                        {/*<div className="mt-auto d-flex">
                                            <img src={process.env.PUBLIC_URL + '/logo2.png'} width={'390'} height={'100%'} className="logo-login" alt="TJABCS" title="TJABCS" />
                                        </div>
*/}

                                        <div className="text-dark font-size-30 mb-4" style={{justifyContent:'center',textAlign:'center'}}><b style={{fontSize:'20px'}}>{isAccept ? "Registrar promoción en Oficialia de Partes - Módulo Electrónico" : "Lineamientos para el uso del Módulo Electrónico."}  </b> </div>

                                        {
                                            qrs.length > 0 ?
                                                <div>
                                                    <button style={{ marginRight: 5 }} onClick={() => downloadtickets()}>
                                                        Descargar
                                                    </button>

                                                    <ReactToPdf targetRef={refparent} filename="etiquetas.pdf" x={.5} y={.5} scale={0.8}>
                                                        {({ toPdf }) => (
                                                            <button style={{ paddingLeft: 5 }} onClick={toPdf}>Generar pdf</button>
                                                        )}
                                                    </ReactToPdf>
                                                    <div className="row" style={{ width: '900px', height: 'auto', margin: 0 }} ref={refparent}>
                                                        {
                                                            qrs.map(element => {
                                                                
                                                                return <Ticket style={{ width: '410px' }} addref={addref} codeTag={element.codeTag} npaquete={element.paqNumber} totalpaquetes={element.totalPaquetes} demanda={demandaresp} expireDate={moment(element.expireDate).format('DD/MM/YYYY hh:mm A') } createDate={moment(element.createDate).format('DD/MM/YYYY hh:mm A') } qrvalue={element.tkn} />
                                                            })
                                                        }


                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        isAccept ? <form onSubmit={handleSubmit} >
                                                            <div className="row">
                                                                <div className="form-group col-12">


                                                                    <label><b>Selecciona el tipo de procedimiento</b></label>
                                                                    {

                                                                        <select className="form-control" name='tipo' onChange={(e) => setVlistselected(e.target.value)} value={vlistselected} required >
                                                                            {
                                                                                vlist.map((item, index) => {
                                                                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                                                                })
                                                                            }
                                                                        </select>

                                                                    }
                                                                </div>
                                                            </div>
                                                            {vlistselected >= 0 && <div>
                                                                <div className="row">
                                                                    <div className="form-group col-12">


                                                                        <label><b>Tipo de promoción</b></label>

                                                                        {

                                                                            <select className="form-control" name='tipo' onChange={(e) => setTipo(e.target.value)} value={tipo} required >
                                                                                {
                                                                                    promocionesTipo.map((item, index) => {
                                                                                        return <option key={item.promocionTipoId} value={item.promocionTipoId}>{item.promocionTipoShortName}</option>
                                                                                    })
                                                                                }
                                                                            </select>

                                                                        }

                                                                    </div>
                                                                    {
                                                                        isExpediente &&
                                                                        <div className="col-12">
                                                                            <label><b>Tipo y Número de expediente</b></label>
                                                                            <select className="form-control" name='formatos' onChange={(e) => setTdemselected(e.target.value)} value={tdemselected} required >
                                                                                {
                                                                                    tdems.map((item, index) => {
                                                                                        return <option key={item.demandaTipoId} value={item.demandaTipoId}>{item.demandaTipoShortName}</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            <div className="col-12 row">

                                                                                <div className="form-group col-sm-12 col-lg-2">
                                                                                    <label><b>Folio</b></label>
                                                                                    <input type="number" min="0" name='expediente' className="form-control" value={expediente} onChange={e => setExpediente(e.target.value)} required />
                                                                                </div>
                                                                                <div className="form-group col-sm-12 col-lg-2">
                                                                                    <label><b>Ejercicio</b></label>
                                                                                    <select className="form-control" name='year' onChange={(e) => setYear(e.target.value)} value={year} required >
                                                                                        {
                                                                                            years.map((item, index) => {
                                                                                                return <option key={'dy' + index} value={item}>{item}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                {/*(formatIndex !== 2 && formatIndex !== 6) && <div className="form-group col-sm-12 col-lg-2">
                                                                                    <label>Procedimiento</label>
                                                                                    <input className="form-control" type="text" disabled value={formatoPrefix} onChange={e => console.log(e)} />

                                                                                </div>
                                                                                */}
                                                                                {(tdemselectedobj && tdemselectedobj.demandaReqSala) && <div className="form-group col-sm-12 col-lg-3">
                                                                                    <label><b>Sala</b></label>
                                                                                    <select className="form-control" name='sala' onChange={(e) => setSalaByValue(e.target.value)} value={sala.ShortName} required >
                                                                                        {
                                                                                            catSalas.map((item, index) => {
                                                                                                return <option key={'ds' + index} value={item.ShortName}>{item.ShortName}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                     <div className="form-group col-12">
                                                                        <label><b>Cantidad de sobres</b><i>&nbsp; (Dimensión máxima por sobre 37cm. Alto x 29cm. Largo y un ancho de hasta 9cm.)</i></label>
                                                                        <input type="number" min="1" max="10" name='promovente' className="form-control" value={cantidadPaquetes} onChange={e => setCantidadPaquetes(e.target.value)} required />
                                                                    </div>
                                                                    <div className="form-group col-12">
                                                                        <label><b>Promovente</b></label>
                                                                        <input type="text" name='promovente' minLength="5" className="form-control" value={promovente} onChange={e => setPromovente(e.target.value)} required />
                                                                    </div>
                                                                   
                                                                    <div className="form-group col-12">
                                                                        <label><b>Correo electrónico</b></label>
                                                                        <input type="text" name='email' className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
                                                                    </div>
                                                                    <div className="form-group col-12">
                                                                        <label><b>Confirmar correo electrónico</b></label>
                                                                        <input type="text" name='repeatemail' className="form-control" value={repeatEmail} onChange={e => setRepeatEmail(e.target.value)} required />
                                                                    </div>
                                                                    <div className="form-group col-12">
                                                                        <label><b>Comentarios</b></label>
                                                                        <textarea type="text" name='detalles' className="form-control" value={detalles} onChange={e => setDetalles(e.target.value)} />
                                                                    </div>
                                                                    <div className="form-group col-12">

                                                                        {/* <LoadCanvasTemplateNoReload />*/}
                                                                        <ReCaptcha
                                                                            // ref={ref => this.recaptcha = ref}
                                                                            sitekey="6LeiBqoaAAAAAF6nsfxzhmv2mg4ONI5HLgEEn4VS"
                                                                            action='action_name'
                                                                            verifyCallback={verifyCallback}
                                                                        />


                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="text-center btn btn-primary bg-accent-color-2 w-100 font-weight-bold font-size-18">Registrar</button>
                                                            </div>}
                                                        </form>
                                                            :
                                                            <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                                                                <p  style={{textIndent:'35.4pt'}}>Los usuarios que opten por la
presentación y recepción de documentos a través del módulo electrónico deberán observar
los siguientes puntos:</p>

<p  style={{textIndent:'35.4pt'}}>PRIMERO.- El módulo electrónico,
es una herramienta tecnológica que otorga a los usuarios la posibilidad de
presentar promociones, fuera del horario laboral así como en días inhábiles, excepto,
en aquellos periodos que estén destinados a vacaciones generales del personal
del Tribunal.</p>

<p  style={{textIndent:'35.4pt'}}>SEGUNDO.- La utilización del
módulo electrónico, no otorga, limita o priva los plazos que conforme a las
disposiciones legales se encuentran establecidos para el ejercicio de las
acciones legales de los procedimientos que son competencia del Tribunal.</p>

<p  style={{textIndent:'35.4pt'}}>TERCERO.- El usuario al presentar
algún documento, promoción, demanda, escrito libre, etc., a través del módulo
electrónico deberá:</p>

<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}>1.<span>&nbsp;&nbsp;&nbsp; </span>Entrar al sitio
web del Tribunal <b>https://www.tjabcs.gob.mx</b> y seleccionar la opción
“Oficialía de partes: módulo electrónico”; será direccionado al vinculo
correspondiente; o ingresar directamente al sitio web: <b>https://buzon.tjabcs.gob.mx</b>
</p>


<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}>2.<span>&nbsp;&nbsp;&nbsp; </span>Capturar la
información requerida: </p>

<p   style={{marginLeft:'53.4pt',
textAlign:'left'}}>Selecciona la opción “Generar Etiqueta QR” / acepta los
términos y condiciones y a continuación Captura la información.</p>

<p  
 style={{marginLeft:'72.0pt',textAlign:'left',textIndent:'-18.0pt',lineHeight:'107%'}}><b>a.<span>&nbsp;&nbsp;&nbsp; </span></b><b>Tipo de
Procedimiento</b></p>

<p  style={{marginLeft:'81.0pt'}}>Los asuntos que trata el tribunal
son relacionados a Procedimiento Contencioso, Administrativo, Responsabilidades
Administrativas (Faltas Graves) y Varios (según sea el caso)</p>

<p   style={{marginLeft:'72.0pt',textAlign:'left',
textIndent:'-18.0pt',lineHeight:'107%'}}><b>b.<span>&nbsp;&nbsp;&nbsp;
</span></b><b>Tipo de Promoción</b></p>

<p  style={{marginLeft:'81.0pt'}}>Pueden ser: demandas iniciales,
expedientes de responsabilidad administrativa, atención a requerimientos,
interposición de recursos ordinarios, entre otras</p>

<p   style={{marginLeft:'72.0pt',textAlign:'left',
textIndent:'-18.0pt',lineHeight:'107%'}}><b>c.<span >&nbsp;&nbsp;&nbsp;
</span></b><b>Tipo y Número de Expediente</b></p>

<p  style={{marginLeft:'81.0pt'}}>Deberá indicar el tipo de
expediente de su asunto, así como proporcionar el número de expediente y sala
al que se dirige cuando se trate de promociones en las que previamente lo tenga
asignado y sea de su conocimiento, quedan exceptuadas de señalarlo, aquellas
promociones que constituyan la apertura de expedientes nuevos.</p>

<p   style={{marginLeft:'81.0pt',textAlign:'left',
textIndent:'-18.0pt',lineHeight:'107%'}}><b>d.<span >&nbsp;&nbsp;&nbsp;
</span></b><b>Cantidad de Sobres</b></p>

<p  style={{marginLeft:'81.0pt'}}>Las medidas del sobre que se
recomienda utilizar son de un máximo de: 37cm. Alto x 29cm. Largo y un Ancho de
hasta 9cm. por lo que deberá considerar el volumen de la documentación que
presentará dentro del o los sobre (s) correspondientes a la promoción en
cuestión.</p>

<p   style={{marginLeft:'72.0pt',textAlign:'left',
textIndent:'-18.0pt',lineHeight:'107%'}}><b>e.<span >&nbsp;&nbsp;&nbsp;
</span></b><b>Promovente</b></p>

<p  style={{marginLeft:'81.0pt'}}>Deberá indicar el nombre del
promovente</p>

<p   style={{marginLeft:'72.0pt',textAlign:'left',
textIndent:'-18.0pt',lineHeight:'107%'}}><b>f.<span >&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Correo Electrónico</b></p>

<p  style={{marginLeft:'81.0pt'}}>Correo electrónico valido para la
generación y envío de la(s) ¨etiqueta (s)¨ (código de barras bidimensional QR)
así como para la recepción del acuse provisional.</p>

<p   style={{marginLeft:'72.0pt',textAlign:'left',
textIndent:'-18.0pt',lineHeight:'107%'}}><b>g.<span >&nbsp;&nbsp;&nbsp;
</span></b><b>Comentarios</b></p>

<p  style={{marginLeft:'81.0pt'}}>Permite al promovente comentar
brevemente respecto a su promoción.</p>

<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}><b>3.<span
>&nbsp;&nbsp;&nbsp; </span></b>El sistema generará
¨etiqueta (s)¨ (código de barras bidimensional o QR que será remitido a la
dirección de correo electrónico indicada por el usuario); contendrá la
información capturada deberá imprimir y adherir al o los sobre (s) que contenga
la documentación a presentar en el módulo electrónico; <b>cada etiqueta es
única para cada sobre (1 etiqueta por sobre).</b></p>

<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}>4.<span
>&nbsp;&nbsp;&nbsp; </span>La (s) ¨etiqueta
(s)¨ (código de barras bidimensional o QR) podrá ser utilizada (s) en la
presentación y recepción de documentos a través del módulo electrónico<b> dentro
del lapso de 48 horas</b> contadas a partir de su generación.</p>



<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}>5.<span
>&nbsp;&nbsp;&nbsp; </span> Si el usuario no
presenta la documentación dentro del plazo referido, será (n) cancelada (s) de
manera automática y quedarán sin efecto alguno, debiendo generar un nuevo
código, observando los pasos 1 a 3 del presente artículo.</p>



<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}>6.<span
>&nbsp;&nbsp;&nbsp; </span>Al acudir al
módulo electrónico de recepción de promociones y documentos, deberá tener a
disposición en todo momento (electrónico o impreso) la (s) ¨etiqueta (s)¨ (código
de barras bidimensional o QR enviado al correo electrónico). </p>


<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}>7.<span
>&nbsp;&nbsp;&nbsp; </span>Para iniciar con
el proceso de recepción de la documentación, el usuario deberá pasar la (s)
¨etiqueta (s)¨ (código de barras bidimensional o QR enviado al correo
electrónico) por el lector óptico, una vez que sea leído, se abrirá la rendija
que permitirá introducir el sobre que contiene la documentación a presentar. </p>


<p  style={{marginLeft:'71.4pt'}}>En el caso de
que fuese más de un sobre, deberá seguir el procedimiento descrito en el
párrafo anterior, deberá presentarlos cada uno con su etiqueta propia, en orden
numérico conforme a las etiquetas se generaron.</p>


<p  style={{marginLeft:'71.4pt'}}>Los códigos QR
son de uso único, por lo que una vez utilizado, ya no es posible utilizarlo
nuevamente.</p>



<p  style={{marginLeft:'71.4pt',textIndent:'-18.0pt'}}>8.<span
>&nbsp;&nbsp;&nbsp; </span>Concluida la
presentación del o los sobre (s), el sistema registrará la fecha y hora en que
fue presentada, así mismo, será enviada dicha información al correo electrónico
señalado, dándose con ello, la terminación del proceso de presentación y
recepción de documentos. </p>


<p  style={{textIndent:'35.4pt'}}>CUARTO.- Las promociones y demás
documentos que se reciban en este módulo serán abiertas, recepcionadas y
registradas por el Oficial de Partes del Tribunal, hasta el día hábil siguiente
de aquel en que fueron depositados. El usuario, podrá dar seguimiento al
estatus de su promoción en el sitio web: <b>https://buzon.tjabcs.gob.mx</b> </p>

<p  style={{textIndent:'35.4pt'}}>QUINTO.- En caso de falla,
anomalía, o cualquier otra circunstancia que se presente que impida la recepción
de la documentación por medio del módulo electrónico, si corresponden a
promociones al día de su vencimiento, deberá contactar al Secretario que se
encuentre autorizado para la recepción de la documentación de que se trate, el
cual se identifica en del “<u>Aviso de Guardia</u>” publicado en la página web:
https://www.tjabcs.gob.mx/category/avisos/ y en los estrados del Tribunal.</p>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={isAccept}
                                                                            onChange={e => setIsAccept(e.target.checked)}
                                                                            name="checkedB"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Aceptar"
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </LoadingOverlay>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};



export default Formulario;
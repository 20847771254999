import React, { Component, Fragment, useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import https from 'https';
import Ticket from '../Ticket';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
//import ReCAPTCHA from "react-google-recaptcha";
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Pendiente de recepción', 'Sobre entregado en buzón', 'Sobre recepcionado por oficialia de partes'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'El estatus actual de tu paquete es de etiqueta generada, el paquete aún no ha sido ingresado al buzón ';
        case 1:
            return 'Sobre entregado en buzón';
        case 2:
            return 'Sobre recepcionado por oficialia de partes';
        default:
            return 'Unknown step';
    }
}

const Seguimiento = props => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [data, setData] = useState(null);
    const [number, setNumber] = useState(0);
    const [year, setYear] = useState(2021);
    const [email, setEmail] = useState("");
    const steps = getSteps();
    const [years, setYears] = useState([2021]);



    useEffect(() => {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
        setYears(range(currentYear, 2020, -1));
        setYear(currentYear);
    }, [])

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const instance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false
            })
        });
        setIsLoading(true);
        //https://apibuzon.tjabcs.gob.mx/api/consulta?email=hugo.carrisoza@tjabcs.gob.mx&year=2021&n=1
        instance.get(`https://apibuzon.tjabcs.gob.mx/api/consulta`, {
            params: {
                email,
                year,
                n: number,
            }
        }).then(res => {
            setIsLoading(false);
            console.log("respuesta", res.data);
            setData(res.data);

        }).catch(err => {
            setIsLoading(false);
            console.log("no encontrado", err);
            Swal.fire("No encontrado", "No se encontro registro con esos datos, corrobora la información", 'error');
        });

    }


    const [isLoading, setIsLoading] = useState(false);
    return (
        <Fragment >

            {//this.state.loading ? <div></div> :
                <div style={{ backgroundColor: "#c0b49c" }}>
                    <div className="container loginbackground" style={{ padding: '3%' }} >
                        
                        <div className="row d-flex ">
                            <div className="col-12 col-md-12 col-lg-12 cardlogin" >

                                <LoadingOverlay
                                    active={isLoading}
                                    spinner
                                    text='Espere un momento...'>
                                    <div className=" bg-white" style={{ padding: '10px' }}>
                                    <Link to="/" className="btn btn-sm btn-primary">Volver</Link>
                                    <hr/>
                                        <div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="form-group col-12">
                                                        {
                                                            data ? <div className={classes.root}>

                                                                {
                                                                    data.etiquetas.map(element => {
                                                                        return <div style={{ border: '1px solid black', marginTop: 5 }}>
                                                                            <span>{`Paquete ${element.paqNumber} de ${element.totalPaquetes},  Etiqueta: ${element.codeTag}`}</span>
                                                                            <Stepper activeStep={element.estatusBuzonId-1}>
                                                                                {steps.map((label, index) => {
                                                                                    const stepProps = {};
                                                                                    const labelProps = {};                    
                                                                                    return (
                                                                                        <Step key={label} {...stepProps}>
                                                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                                                        </Step>
                                                                                    );
                                                                                })}
                                                                            </Stepper>

                                                                        </div>
                                                                    })
                                                                }
                                                                 <Link to="/" className="btn btn-primary">Volver</Link>
                                                            </div> :
                                                                <div>
                                                                <div className="text-dark font-size-30 mb-4"> Identifica el número de promoción de tu etiqueta, el correo electrónico deberá coincidir con el que diste de alta la promoción en buzón</div>
                                                                    <div className="mt-auto d-flex">
                                                                        <img src={process.env.PUBLIC_URL + '/instnumero.png'} width={'390'} height={'100%'} className="logo-login" alt="TJABCS" title="TJABCS" />
                                                                    </div>
                                                                    <div className="col-12 row">
                                                                        <div className="form-group col-sm-12 col-lg-2">
                                                                            <label>No. Promoción</label>
                                                                            <input required type="number" min="0" name='expediente' className="form-control" value={number} onChange={e => setNumber(e.target.value)} required />
                                                                        </div>
                                                                        <div className="form-group col-sm-12 col-lg-2">
                                                                            <label>Año</label>
                                                                            <select className="form-control" name='year' onChange={(e) => setYear(e.target.value)} value={year} required >
                                                                                {
                                                                                    years.map((item, index) => {
                                                                                        return <option key={'dy' + index} value={item}>{item}</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-sm-12 col-lg-4">
                                                                            <label>Correo de registro</label>
                                                                            <input required type="email" name='email' className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
                                                                        </div>
                                                                    </div>

                                                                    <button type="submit" className="text-center btn btn-primary bg-accent-color-2 w-100 font-weight-bold font-size-18">Buscar</button>
                                                                </div>

                                                        }


                                                    </div>



                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </LoadingOverlay>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};



export default Seguimiento;
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import textfile from "../../assets/justificacion.txt";
import textfile2 from "../../assets/normatividad.txt";
import DocViewer from '../DocViewer';
import FileViewer from 'react-file-viewer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import parse from 'html-react-parser';
const useStyles = makeStyles({
    root: {

        maxWidth: '90%',
    },
    media: {
        height: '30vh',
    },
});
export default function Dashboard() {
    const classes = useStyles();
    const [text, setText] = React.useState("");
    const [text2, setText2] = React.useState("");

    fetch(textfile)
        .then((response) => response.text())
        .then((textContent) => {
            setText(textContent);
        });

    fetch(textfile2)
        .then((response) => response.text())
        .then((textContent) => {
            setText2(textContent);
        });
    return (
        <Fragment>
            <div className="grupo">
                <div className="container row">
                    <div className="col-md-2">
                        <a href="https://www.tjabcs.gob.mx"><figure className="logo">
                            <img className="img-responsive" src="https://www.tjabcs.gob.mx/wp-content/themes/TJABCS/img/logotipo.png" alt="Tribunal de Justicia Administrativa del Estado de Baja California Sur" title="Tribunal de Justicia Administrativa del Estado de Baja California Sur" />
                        </figure>
                        </a>
                    </div>
                    <div style={{ margin: 'auto 0' }}>
                        <h2 style={{ fontSize: 29 }}><b>Oficialia de partes - Módulo electrónico</b></h2>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: "#303030", height: 50 }}>
                <div className="col-md-12">
                    <nav class="wsmenu slideLeft clearfix color_primary" style={{ width: 700, margin: 'auto' }}>
                        <ul class="text-center mobile-sub wsmenu-list" >
                            <li style={{ width: 100, float: 'left', fontWeight: 'bold' }} id="menu-item-74" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-74 active"><a title="Inicio" href="https://www.tjabcs.gob.mx/"><span class="bottom-line"></span>Inicio</a></li>
                            <li style={{ width: 230, float: 'left', fontWeight: 'bold' }} id="menu-item-74" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-74 active"><Link to="/generar"><a title="Generar QR" ><span class="bottom-line"></span>Generar Etiqueta QR</a></Link></li>
                            <li style={{ width: 230, float: 'left', fontWeight: 'bold' }} id="menu-item-74" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-74 active"><Link to="/seguimiento"><a title="Estatus de promoción"><span class="bottom-line"></span>Estatus de mi promoción </a></Link></li>

                        </ul>

                    </nav>
                </div>
            </div>

            {/* <div className="col-sm-12 col-md-6">
                    <FileViewer
                        fileType={"docx"}
                        filePath={"https://buzon.tjabcs.gob.mx/justificacion.docx"}
                        key={"archjustificacion"}

                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <DocViewer source="https://buzon.tjabcs.gob.mx/normatividad.docx" />
    </div>*/}

            <Accordion className="col-12">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading} style={{fontWeight:'bold'}}>Justificación</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {parse(text)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="col-12">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading} style={{fontWeight:'bold'}}>Normatividad</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {parse(text2)}
                    </Typography>
                </AccordionDetails>
            </Accordion>


            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={process.env.PUBLIC_URL + '/qrcode2.png'}
                                title="Generacion de etiquetas QR"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Generar etiqueta
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Genera una etiqueta para el módulo electrónico del TJABCS, coloca la etiqueta en tu entrega, escanea en el buzón y entrega.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to="/generar" className="btn btn-primary">Generar</Link>
                        </CardActions>
                    </Card>
                </div>
                <div className="col-sm-12 col-md-6">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={process.env.PUBLIC_URL + '/searching.png'}
                                title="Estatus de promoción"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Estatus de mi promoción
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Entregaste un paquete en el módulo electrónico? Comprueba el estatus de tu promoción aquí
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to="/seguimiento" className="btn btn-primary">Seguimiento</Link>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </Fragment>
    )
};

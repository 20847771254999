import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import React, { useRef,useEffect } from 'react';
const QRCode = require('qrcode.react');

const ComponentToPrint = React.forwardRef((props, ref) => (

    <div ref={ref} style={{width:'302px',padding:2}}>
        <div style={{ width: '300px', borderStyle: 'solid' }}>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/logo2.png'} width={'230'} height={'100%'} className="logo-login" alt="TJABCS" title="TJABCS" />
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <h4>OFICIALIA DE PARTES</h4>
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <h6>Buzón Electrónico</h6>
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <h6>{props.npaquete} de {props.totalpaquetes}</h6>
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <QRCode value={props.qrvalue} />
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <h6>{props.codeTag}</h6>
            </div>
            
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <h6>Expediente:</h6>
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <label>{props.demanda}</label>
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <h6>Vigencia:</h6>
            </div>
            <div style={{ display: 'table', margin: '0 auto', justifyContent: 'center' }}>
                <label>{props.expireDate}</label>
            </div>
            
            <div style={{ display: 'table', margin: '0 10px', justifyContent: 'left' }}>
                <label style={{fontSize:'11px'}}><i>Creado:&nbsp;{props.createDate}</i></label>
            </div>
            <br /><br />
        </div>

    </div>
));

const Ticket = (props) => {
    const componentRef = useRef();
    useEffect(()=>{
       // console.log("reff",componentRef);
        props.addref(componentRef);
    },[]);
    return (
        <React.Fragment style={{width:'410px'}}>
            <ComponentToPrint w={400} style={{width:'410px',height:'auto'}} createDate={props.createDate} npaquete={props.npaquete} codeTag={props.codeTag} totalpaquetes={props.totalpaquetes} demanda={props.demanda} expireDate={props.expireDate} qrvalue={props.qrvalue} ref={componentRef} />
            {/*<button onClick={() => exportComponentAsPNG(componentRef)}>
                Descargar
    </button>*/}
        </React.Fragment>
    );
};

export default Ticket;
import './App.css';
import Formulario from './components/Formulario';
import Dashboard from './components/Dashboard';
import Seguimiento from './components/Seguimiento';
import Pdf from './components/Formulario/pdf';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function App() {
  return (
<Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/generar" >
            <Formulario />
          </Route>
          <Route path="/seguimiento" >
            <Seguimiento />
          </Route>
          <Route path="/pdf" >
            <Pdf />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

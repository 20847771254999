import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
const QRCode = require('qrcode.react');

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

const Pdf = props => {
    return <Document style={{ width: '100vw', height: '100vh' }}>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
            
            </View>
            <View style={styles.section}>
                <Text>Section #2</Text>
            </View>
        </Page>
    </Document>;
};

const PdfView = (prosp) => {
    return (
    <PDFViewer style={{ width: '100vw', height: '99vh' }}>
        <Pdf  />
    </PDFViewer>)
}

export default PdfView;